.footer {
  background-color: #111111;
  padding: 100px;
  text-align: start;
  position: relative;
  color: white;
  scroll-snap-align: center;
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: start;
  margin-top: 50px;
  margin-bottom: 50px;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer button {
  margin-right: 15px;
  margin-bottom: 15px;
}

.footer-text {
  margin-left: 50px;
}

@media (max-width: 768px) {
  .footer {
    text-align: start;
    padding: 50px;
  }

  .footer-middle {
    display: block;
    justify-content: space-between;
    align-items: flex-end;
    text-align: start;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .footer-column {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-text {
    margin-left: 0;
    margin-top: 50px;
  }
}
