.custom-button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: fit-content;
}

.custom-button.black {
  color: #1b1b1b;
  border-color: #1b1b1b;
}

.custom-button.black:hover {
  color: white;
  background-color: #1b1b1b;
  border-color: transparent;
}

.custom-button:hover {
  background-color: white;
  color: #1b1b1b;
  border: none;
}
