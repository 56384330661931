.navbar {
  position: fixed;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.navbar a {
  color: black;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  text-decoration: none;
  position: relative;
}

.navbar a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border: inherit;
  transition: transform 0.3s;
  z-index: -1;
}

.navbar a.active::before {
  background-color: #cbff36;
  border: none;
  transform: rotate(45deg);
}

.navbar a:hover::before {
  background-color: #cbff36;
  border: none;
  transform: rotate(45deg);
}

.navbar a.section3-active {
  color: white;
}

.navbar a.section3-active::before {
  background-color: black;
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .navbar {
    left: -100px;
  }
}
