@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.work {
  animation: slideUp 1s ease-out forwards;
  overflow: scroll;
  height: 100vh;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-align: center;
  overflow-x: hidden;
}

.work h2 {
  margin: 100px;
}

.box {
  background-color: #f4f4f4;
  padding: 50px;
  margin: 100px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.app-icon {
  height: 75px;
  width: 75px;
}

@media (max-width: 768px) {
  .work h2 {
    margin: 30px;
  }

  .box {
    padding: 30px;
    margin: 30px;
    margin-bottom: 100px;
  }
}
