@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.home {
  overflow: scroll;
  height: 100vh;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-behavior: auto;
  overflow-x: hidden;
}

.section1,
.section2,
.section4 {
  height: 100vh;
  margin: 100px;
  text-align: center;
  align-content: center;
  scroll-snap-align: center;
}

.image.appear-from-bottom {
  animation: slideInFromBottom 1s ease-in-out forwards;
}

.section1 .image {
  max-width: calc(45% - 50px);
  height: auto;
  flex: 1;
  margin-left: 50px;
  animation: slideUp 1s ease-out forwards;
}

.home-row {
  justify-content: center;
  display: flex;
  gap: 50px;
}

.section3 {
  height: 100vh;
  background-color: #eefdfc;
  display: flex;
  flex-direction: row;
  gap: 100px;
  padding: 100px;
  align-items: center;
  scroll-snap-align: center;
}

.section3 h2 {
  margin-bottom: 10px;
}

.home h2 {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .home {
    overflow: scroll;
    height: 100vh;
    scroll-snap-points-y: none;
    scroll-snap-type: none;
    scroll-behavior: auto;
    overflow-x: hidden;
    padding-top: 100px;
  }

  .section1,
  .section2,
  .section4 {
    height: auto;
    margin: 50px;
    text-align: center;
    align-content: center;
    scroll-snap-align: center;
  }

  .section1 .image {
    max-width: 100%;
    height: auto;
    flex: 1;
    margin-bottom: 50px;
    margin-left: 0;
  }

  .section3 {
    height: auto;
    display: block;
    flex-direction: column;
    gap: 100px;
    padding-left: 0;
    padding-right: 0;
    align-items: start;
    text-align: start;
    padding: 50px;
    scroll-snap-align: center;
  }

  .home h2 {
    margin: 0;
  }

  .home-row {
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
