@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.welibri {
  overflow-x: hidden;
  overflow: scroll;
}

.preview {
  animation: slideDown 1s ease-out forwards;
  width: 100%;
  position: relative;
}

.welibri h1 {
  margin-left: 100px;
}

@media (max-width: 768px) {
  .welibri h1 {
    margin-left: 30px;
  }

  .preview {
    height: 300px;
    object-fit: cover;
  }
}
