.App {
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

@font-face {
  font-family: 'NyghtSerif';
  src: url('./assets/fonts/NyghtSerif-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App h1 {
  font-family: 'NyghtSerif';
  src: url('./assets/fonts/NyghtSerif-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 96px;
}

.App h2 {
  font-family: 'NyghtSerif';
  src: url('./assets/fonts/NyghtSerif-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 54px;
}

.App h3 {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
}

.App p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.form-group {
  position: relative;
  margin-bottom: 25px;
  width: 100%;
}

label {
  position: absolute;
  left: 0;
  top: 15px;
  color: #1b1b1b;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #1b1b1b;
  transition: top 0.3s, font-size 0.3s;
}

input[type='text'] {
  outline: none;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  width: 100%;
  padding-top: 20px;
}

input,
textarea {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #1b1b1b;
  border-radius: 0;
  outline: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  resize: none;
  transition: top 0.3s, font-size 0.3s;
  padding: 10px 10px;
}

input[type='text']:focus ~ label {
  top: -10px;
  font-size: 12px;
}

input[type='text']:focus {
  border-bottom: 1px solid #1b1b1b;
}

textarea:focus {
  border-bottom: 1px solid #1b1b1b;
}

textarea ~ label {
  top: -30px;
}
