@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.gallery {
  animation: slideUp 1s ease-out forwards;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.image-gallery {
  justify-content: center;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin: 100px;
}

.gallery .image {
  height: 300px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image-gallery {
    gap: 10px;
    margin: 30px;
  }

  .gallery .image {
    height: auto;
    width: 100%;
  }
}
